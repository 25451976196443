<script lang="ts">
  import { AppConfig } from "$lib/config/config"
  import { navbarEnabled, footerEnabled } from "$lib/client/LayoutContext"

  navbarEnabled.set(true)
  footerEnabled.set(true)

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: AppConfig.WebsiteName,
    url: AppConfig.WebsiteBaseUrl,
  }
  const jsonldScript = `<script type="application/ld+json">${
    JSON.stringify(ldJson) + "<"
  }/script>`
</script>

<svelte:head>
  <title>{AppConfig.WebsiteName}</title>
  <meta name="description" content={AppConfig.WebsiteDescription} />
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  {@html jsonldScript}
</svelte:head>

<div class="container mx-auto">
  <section
    id="home"
    class="w-full py-2 md:py-4 lg:py-8 xl:py-16 text-white bg-gradient-to-l from-primary to-accent mt-8 py-8"
  >
    <div class="container mx-auto px-12 md:px-12" data-id="18">
      <div
        class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]"
        data-id="19"
      >
        <img
          data-id="20"
          alt="Musicians performing on stage"
          class="mx-auto rounded-xl object-cover object-center sm:w-full lg:order-last"
          src="/images/landing/logo-hero-sm.jpg"
        />
        <div class="flex flex-col justify-center space-y-4" data-id="21">
          <div class="space-y-2" data-id="22">
            <h1
              class="text-2xl font-bold tracking-tighter sm:text-3xl xl:text-4xl/none mb-8"
              data-id="23"
            >
              Connect. Perform. Thrive.
            </h1>
            <p
              class="max-w-[600px] text-gray-300 md:text-xl italic mb-6"
              data-id="24"
            >
              An AI-powered platform helping musicians, venues, and fans come
              together.
            </p>
            <p
              class="max-w-[600px] text-gray-300 md:text-xl italic"
              data-id="24"
            >
              Music connects us with our deepest emotions—those who create it
              deserve to thrive!
            </p>
          </div>
          <a
            href="/join"
            class="btn-wide btn btn-outline text-white w-full sm:w-[10rem] xl:w-[20rem]"
            data-id="26">Join waiting list!</a
          >
        </div>
      </div>
    </div>
  </section>

  <section
    id="how-it-works"
    class="w-full py-12 py-2 md:py-4 lg:py-8 xl:py-16 bg-gray-100 py-8"
  >
    <div class="container px-4 md:px-6 mx-auto" data-id="29">
      <h2
        class="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12"
        data-id="30"
      >
        How It Works
      </h2>
      <div class="grid gap-6 lg:grid-cols-3 lg:gap-12" data-id="31">
        <div
          class="works flex flex-col items-center space-y-4 text-center card bg-white p-6"
          data-id="32"
        >
          <div class="step-icon">🎵</div>
          <h3 class="font-bold">Create Your Profile</h3>
          <p>
            Musicians showcase their talent, venues list their spaces, and fans
            set their preferences. Build a profile tailored to your role in the
            music community.
          </p>
        </div>
        <div
          class="works flex flex-col items-center space-y-4 text-center card bg-white p-6"
          data-id="32"
        >
          <div class="step-icon">🤖</div>
          <h3 class="font-bold">Automate & Grow with AI</h3>
          <p>
            Let AI handle time-consuming tasks: booking, social media content
            creation & promotion, career guidance, and networking—giving you
            more time to create and perform.
          </p>
        </div>
        <div
          class="works flex flex-col items-center space-y-4 text-center card bg-white p-6"
          data-id="32"
        >
          <div class="step-icon">🤝</div>
          <h3 class="font-bold">Book, Network & Discover</h3>
          <p>
            Musicians find venues, venues discover talent, and fans explore
            local scenes. Designed to help musicians, venues, and fans
            seamlessly connect.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section
    id="musicians"
    class="w-full py-2 md:py-4 lg:py-8 xl:py-16 text-white bg-gradient-to-r from-primary to-accent mt-8 py-8"
  >
    <div class="container mx-auto px-12 md:px-12" data-id="18">
      <div
        class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_700px]"
        data-id="19"
      >
        <img
          alt="Musicians performing on stage"
          class="mx-auto rounded-xl object-cover object-center sm:w-full aspect-ratio"
          src="/images/landing/gig.jpg"
        />
        <div class="flex flex-col justify-center space-y-4" data-id="21">
          <div class="space-y-2" data-id="22">
            <h1
              class="text-2xl font-bold tracking-tighter sm:text-3xl xl:text-4xl/none mb-8"
              data-id="23"
            >
              Musicians
            </h1>
            <ul class="musician-list">
              <li>Automate Social media content creation & promotion</li>
              <li>
                Boost success with personal AI assistant for day-to-day tasks,
                tailored advice and admin
              </li>
              <li>Get discovered by venues and fans</li>
              <li>Manage your bookings & payments efficiently</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    id="venues"
    class="w-full py-12 py-2 md:py-4 lg:py-8 xl:py-16 bg-gray-100 py-8"
  >
    <div class="container mx-auto px-12 md:px-12">
      <div
        class="grid gap-6 lg:grid-cols-[1fr_600px] lg:gap-12 xl:grid-cols-[1fr_700px]"
      >
        <div class="flex flex-col justify-center space-y-4">
          <div class="space-y-2" data-id="22">
            <h1
              class="text-2xl font-bold tracking-tighter sm:text-3xl xl:text-4xl/none mb-8"
              data-id="23"
            >
              Venues
            </h1>
            <ul class="musician-list">
              <li>Find the perfect artists for your space</li>
              <li>
                Enhance efficiency with smoother booking, automated payments,
                and simplified admin
              </li>
              <li>Increase event visibility</li>
            </ul>
          </div>
        </div>
        <img
          alt="Bar venue"
          class="mx-auto rounded-xl object-cover object-center sm:w-full aspect-ratio lg:order-last"
          src="/images/landing/venue.jpg"
        />
      </div>
    </div>
  </section>

  <section
    id="fans"
    class="w-full md:py-4 lg:py-8 xl:py-16 text-white bg-gradient-to-l from-primary to-accent mt-8 py-8"
  >
    <div class="container mx-auto px-12 md:px-12" data-id="18">
      <div
        class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_700px]"
        data-id="19"
      >
        <img
          alt="Fans enjoying a concert"
          class="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full"
          height="310"
          width="550"
          src="/images/landing/fan.jpg"
        />
        <div class="flex flex-col justify-center space-y-4" data-id="21">
          <div class="space-y-2" data-id="22">
            <h1
              class="text-2xl font-bold tracking-tighter sm:text-3xl xl:text-4xl/none mb-8"
              data-id="23"
            >
              Music Fans
            </h1>
            <ul class="musician-list">
              <li>Discover local music events in a few clicks</li>
              <li>Follow your favorite artists</li>
              <li>Never miss a show</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  {#if AppConfig.ShowWaitingList}
    <div class="p-12 sm:p-24 text-center">
      <h1 class="text-xl font-medium mb-8">
        Get early access to new features!
      </h1>

      <div class="text-xl">
        <a class="btn btn-primary btn-circle btn-wide text-xl" href="/join"
          >Join waiting list!</a
        >
      </div>
    </div>
  {/if}
</div>

{#if false}
  <div class="min-h-[60vh]">
    <div class="pt-20 pb-8 px-7">
      <div class="max-w-lg mx-auto text-center">
        <div
          class="text-3xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent"
        >
          Explore our platform
        </div>
      </div>

      <div
        class="flex gap-6 mt-12 max-w-[1064px] mx-auto place-content-center flex-wrap"
      >
        {#each features as feature}
          <div
            class="card bg-white w-[270px] min-h-[300px] flex-none shadow-xl"
          >
            <div class="card-body items-center text-center p-[24px] pt-[32px]">
              <div>
                <svg
                  width="50px"
                  height="50px"
                  class="mb-2 mt-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                  {@html feature.svgContent}
                </svg>
              </div>
              <h2 class="card-title">
                {feature.name}
              </h2>
              <p class="text-sm">
                {feature.description}
              </p>
              {#if feature.link}
                <a
                  href={feature.link}
                  class="pb-4"
                  target={feature.newPage ? "_blank" : ""}
                >
                  <button
                    class="btn btn-xs btn-outline rounded-full btn-primary min-w-[100px]"
                    >{feature.linkText ? feature.linkText : "Try It"}</button
                  >
                </a>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
{/if}

<style>
  .aspect-ratio {
    aspect-ratio: 16 / 9;
  }

  .works {
    transition: all 0.3s ease;
  }

  .works:hover,
  .works:active {
    transform: translateY(-5px);
    box-shadow: 0px 0px 10px #a58bbb;
  }

  .musician-list li {
    list-style: circle;
    margin-left: 2rem;
  }

  section {
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
</style>
